var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',[_c('div',{staticClass:"bg-darkblue z-50 pt-4 pb-4 flex w-full pl-8 pr-8 fixed justify-between"},[_c('div',[_c('h1',{staticClass:"text-white font-semibold inline-block align-middle mr-7 text-lg"},[_c('router-link',{staticClass:"text-white xl:text-base",attrs:{"to":"/app"}},[_vm._v(" "+_vm._s(_vm.appName)+" ")])],1),_c('button',{staticClass:"cursor-pointer toggler align-middle hidden md:inline-block inline-block focus:ring-2 focus:ring-green-400",attrs:{"id":"toggler1","type":"button "},on:{"click":_vm.ToggleMenu}},[_c('div',{staticClass:"ml-auto mr-auto w-6 h-0.5 mt-1 bg-lightblue"}),_c('div',{staticClass:"w-6 ml-auto mr-auto h-0.5 mt-1 bg-lightblue"}),_c('div',{staticClass:"w-6 ml-auto mr-auto h-0.5 mt-1 bg-lightblue"})])]),_c('div',{staticClass:"flex"},[_c('ul',{staticClass:"h-full"},[_c('li',{staticClass:"inline-block text-white h-full"},[_c('button',{staticClass:"cursor-pointer xl:text-sm align-middle h-full",attrs:{"type":"button "},on:{"click":_vm.Logout}},[_c('img',{staticClass:"w-6 h-auto inline-block",attrs:{"src":_vm.icons.logout}}),_c('span',{staticClass:"hidden md:inline-block"},[_vm._v("Logout")])])]),_c('li',{staticClass:"ml-4 inline-block md:hidden text-white h-full"},[_c('button',{staticClass:"cursor-pointer toggler align-middle focus:ring-2 focus:ring-green-400",attrs:{"id":"toggler2","type":"button "},on:{"click":_vm.ToggleMenu}},[_c('div',{staticClass:"ml-auto mr-auto w-4 md:w-6 h-0.5 mt-1 bg-lightblue"}),_c('div',{staticClass:"w-4 md:w-6 ml-auto mr-auto h-0.5 mt-1 bg-lightblue"}),_c('div',{staticClass:"w-4 md:w-6 ml-auto mr-auto h-0.5 mt-1 bg-lightblue"})])])])])]),_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"bg-lightblue h-screen pt-16 fixed z-30 overflow-y-scroll",class:{
          'fortunate-sidebar-closed': _vm.toggle_menu,
          'fortunate-sidebar': !_vm.toggle_menu,
        },attrs:{"id":"sidebar"}},[_c('ul',{staticClass:"p-6"},[_c('li',{staticClass:"text-darkblue hover:font-bold cursor-pointer mb-4"},[_c('router-link',{staticClass:"block",attrs:{"to":"/app"}},[_c('img',{staticClass:"w-5 h-auto mr-2 inline align-middle",attrs:{"src":_vm.icons.home}}),_c('span',{staticClass:"align-middle md:text-xs"},[_vm._v("Overview")])])],1),(
              _vm.appPermissions.invoice
                ? _vm.appPermissions.invoice.read === true
                : false
            )?_c('li',{staticClass:"text-darkblue hover:font-bold cursor-pointer mb-4"},[_c('router-link',{staticClass:"block",attrs:{"to":"/app/invoices"}},[_c('img',{staticClass:"w-5 h-auto mr-2 inline align-middle",attrs:{"src":_vm.icons.file}}),_c('span',{staticClass:"align-middle md:text-xs"},[_vm._v("Invoice")])])],1):_vm._e(),(
              _vm.appPermissions.statement
                ? _vm.appPermissions.statement.read === true
                : false
            )?_c('li',{staticClass:"text-darkblue hover:font-bold cursor-pointer mb-4"},[_c('router-link',{staticClass:"block",attrs:{"to":"/app/statement"}},[_c('img',{staticClass:"w-5 h-auto mr-2 inline align-middle",attrs:{"src":_vm.icons.file}}),_c('span',{staticClass:"align-middle md:text-xs"},[_vm._v("Statement")])])],1):_vm._e(),(
              _vm.appPermissions.purchase
                ? _vm.appPermissions.purchase.read === true
                : false
            )?_c('li',{staticClass:"text-darkblue hover:font-bold cursor-pointer mb-4"},[_c('router-link',{staticClass:"block",attrs:{"to":"/app/purchases"}},[_c('font-awesome-icon',{class:' text-sm mr-2',attrs:{"title":"Stock","icon":['fa', 'shopping-basket']}}),_c('span',{staticClass:"align-middle md:text-xs"},[_vm._v("Purchases")])],1)],1):_vm._e(),(
              _vm.appPermissions.stock
                ? _vm.appPermissions.stock.read === true
                : false
            )?_c('li',{staticClass:"text-darkblue hover:font-bold cursor-pointer mb-4"},[_c('router-link',{staticClass:"block",attrs:{"to":"/app/stock"}},[_c('font-awesome-icon',{class:' text-sm mr-2',attrs:{"title":"Stock","icon":['fa', 'archive']}}),_c('span',{staticClass:"align-middle md:text-xs"},[_vm._v("Stock")])],1)],1):_vm._e(),(_vm.role.toLowerCase() == 'admin')?_c('li',{staticClass:"text-darkblue hover:font-bold cursor-pointer mb-4"},[_c('router-link',{staticClass:"block",attrs:{"to":"/app/analytics"}},[_c('img',{staticClass:"w-5 h-auto mr-2 inline align-middle",attrs:{"src":_vm.icons.zap}}),_c('span',{staticClass:"align-middle md:text-xs"},[_vm._v("Analytics")])])],1):_vm._e(),(
              _vm.appPermissions.customer
                ? _vm.appPermissions.customer.read === true
                : false
            )?_c('li',{staticClass:"text-darkblue hover:font-bold cursor-pointer mb-4"},[_c('router-link',{staticClass:"block",attrs:{"to":"/app/customers"}},[_c('img',{staticClass:"w-5 h-auto mr-2 inline align-middle",attrs:{"src":_vm.icons.users}}),_c('span',{staticClass:"align-middle md:text-xs"},[_vm._v("Customers")])])],1):_vm._e(),(
              _vm.appPermissions.expense
                ? _vm.appPermissions.statement.read === true
                : false
            )?_c('li',{staticClass:"text-darkblue hover:font-bold cursor-pointer mb-4"},[_c('router-link',{staticClass:"block",attrs:{"to":"/app/expenses"}},[_c('font-awesome-icon',{class:' text-sm mr-2',attrs:{"title":"Expenses","icon":['fa', 'credit-card']}}),_c('span',{staticClass:"align-middle md:text-xs"},[_vm._v("Expenses")])],1)],1):_vm._e(),(
              _vm.appPermissions.expenseitem
                ? _vm.appPermissions.expenseitem.read === true
                : false
            )?_c('li',{staticClass:"text-darkblue hover:font-bold cursor-pointer mb-4"},[_c('router-link',{staticClass:"block",attrs:{"to":"/app/expenseItems"}},[_c('img',{staticClass:"w-5 h-auto mr-2 inline align-middle",attrs:{"src":_vm.icons.pen}}),_c('span',{staticClass:"align-middle md:text-xs"},[_vm._v("Expense Items")])])],1):_vm._e(),(
              _vm.appPermissions.partner
                ? _vm.appPermissions.partner.read === true
                : false
            )?_c('li',{staticClass:"text-darkblue hover:font-bold cursor-pointer mb-4"},[_c('router-link',{staticClass:"block",attrs:{"disabled":"","to":"/app/partners"}},[_c('img',{staticClass:"w-5 h-auto mr-2 inline align-middle",attrs:{"src":_vm.icons.users}}),_c('span',{staticClass:"align-middle md:text-xs"},[_vm._v("Partners")])])],1):_vm._e(),(
              _vm.appPermissions.partner
                ? _vm.appPermissions.partner.read === true
                : false
            )?_c('li',{staticClass:"text-darkblue hover:font-bold cursor-pointer mb-4"},[_c('router-link',{staticClass:"block",attrs:{"disabled":"","to":"/app/sellers"}},[_c('img',{staticClass:"w-5 h-auto mr-2 inline align-middle",attrs:{"src":_vm.icons.users}}),_c('span',{staticClass:"align-middle md:text-xs"},[_vm._v("Sellers")])])],1):_vm._e(),(
              _vm.appPermissions.quote
                ? _vm.appPermissions.quote.read === true
                : false
            )?_c('li',{staticClass:"text-darkblue hover:font-bold cursor-pointer mb-4"},[_c('router-link',{staticClass:"block",attrs:{"to":"/app/quotes"}},[_c('img',{staticClass:"w-5 h-auto mr-2 inline align-middle",attrs:{"src":_vm.icons.users}}),_c('span',{staticClass:"align-middle md:text-xs"},[_vm._v("Quotes")])])],1):_vm._e(),(
              _vm.appPermissions.vehicle
                ? _vm.appPermissions.vehicle.read === true
                : false
            )?_c('li',{staticClass:"text-darkblue hover:font-bold cursor-pointer mb-4"},[_c('router-link',{staticClass:"block",attrs:{"to":"/app/vehicles"}},[_c('font-awesome-icon',{class:' text-sm mr-2',attrs:{"title":"Stock","icon":['fa', 'car']}}),_c('span',{staticClass:"align-middle md:text-xs"},[_vm._v("Vehicles")])],1)],1):_vm._e(),(
              _vm.appPermissions.vehicletrip
                ? _vm.appPermissions.vehicletrip.read === true
                : false
            )?_c('li',{staticClass:"text-darkblue hover:font-bold cursor-pointer mb-4"},[_c('router-link',{staticClass:"block",attrs:{"to":"/app/vehicle-trips"}},[_c('font-awesome-icon',{class:' text-sm mr-2',attrs:{"title":"Stock","icon":['fa', 'map']}}),_c('span',{staticClass:"align-middle md:text-xs"},[_vm._v("Vehicle Trips")])],1)],1):_vm._e(),(
              _vm.appPermissions.role ? _vm.appPermissions.role.read === true : false
            )?_c('li',{staticClass:"text-darkblue hover:font-bold cursor-pointer mb-4"},[_c('router-link',{staticClass:"block",attrs:{"to":"/app/roles"}},[_c('img',{staticClass:"w-5 h-auto mr-2 inline align-middle",attrs:{"src":_vm.icons.users}}),_c('span',{staticClass:"align-middle md:text-xs"},[_vm._v("Roles")])])],1):_vm._e(),(
              _vm.appPermissions.user ? _vm.appPermissions.user.read === true : false
            )?_c('li',{staticClass:"text-darkblue hover:font-bold cursor-pointer mb-4"},[_c('router-link',{staticClass:"block",attrs:{"to":"/app/staff"}},[_c('img',{staticClass:"w-5 h-auto mr-2 inline align-middle",attrs:{"src":_vm.icons.users}}),_c('span',{staticClass:"align-middle md:text-xs"},[_vm._v("Users")])])],1):_vm._e(),_c('li',{staticClass:"text-darkblue hover:font-bold cursor-pointer mb-4"},[_c('router-link',{staticClass:"block",attrs:{"to":"/app/settings"}},[_c('img',{staticClass:"w-5 h-auto mr-2 inline align-middle",attrs:{"src":_vm.icons.settings}}),_c('span',{staticClass:"align-middle md:text-xs"},[_vm._v("Settings")])])],1)])]),_c('div',{staticClass:"d-flex z-10 bg-dashboardbody min-h-screen h-full pt-20",class:{
          'fortunate-main-closed': _vm.toggle_menu,
          'fortunate-main': !_vm.toggle_menu,
        },attrs:{"id":"main-body"}},[_c('div',{staticClass:"ml-3 md:ml-7 md:mr-7 mr-3 min-h-screen"},[_c('div',{staticClass:"fixed z-40 w-11/12 md:w-9/12 pl-2 pr-2 pt-2"},[_c('t-alert',{attrs:{"fixedClasses":_vm.alertConfig.fixedClasses,"classes":_vm.alertConfig.classes,"timeout":_vm.alertTimeout,"variants":_vm.alertConfig.variants,"variant":_vm.alertVariant,"show":_vm.showAlert},on:{"hidden":_vm.AlertHidden}},[(Array.isArray(_vm.alertMessage))?_c('div',[_c('p',[_vm._v(_vm._s(_vm.alertTitle))]),_c('ul',_vm._l((_vm.alertMessage),function(msg,index){return _c('ol',{key:index},[_vm._v(" "+_vm._s(msg)+" ")])}),0)]):_c('span',[_vm._v(" "+_vm._s(_vm.alertMessage))])])],1),_c('router-view',{on:{"show-alert":function($event){return _vm.ShowAlert($event)}}})],1),_vm._m(0)])])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"text-center pt-3 pb-3 mt-5 w-full bg-darkblue"},[_c('p',{staticClass:"text-white"},[_vm._v("Copyright 2021 Fortunate Steel")])])}]

export { render, staticRenderFns }