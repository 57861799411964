<script>
  import gql from "graphql-tag";
  import { Bar, mixins } from "vue-chartjs";
  const { reactiveProp } = mixins;
  export default {
    extends: Bar,
    mixins: [reactiveProp],
    props: {
      SetBarChartLoadingStatus: Function,
      year: String,
      chartData: Object,
    },
    name: "BarChart",
    apollo: {
      getProfitAndExpenses: {
        query: gql`
          query getProfitAndExpenses($year: Int!) {
            getProfitAndExpenses(year: $year) {
              totalExpenses {
                month
                totalExpenses
              }
              totalProfit {
                month
                totalProfit
              }
            }
          }
        `,
        variables() {
          return {
            year: Number(this.year),
          };
        },
        error(error) {
          this.$emit("show-alert", {
            type: "error",
            message: error.message,
          });
        },
        watchLoading(isLoading, countModifier) {
          if (isLoading === true && countModifier === 1) {
            this.SetBarChartLoadingStatus(true);
            return;
          }
          this.SetBarChartLoadingStatus(false);
        },
      },
    },
    data() {
      return {
        getProfitAndExpenses: [],
        chart_data: {
          labels: [
            "January",
            "February",
            "March",
            "April",
            "May",
            "June",
            "July",
            "August",
            "September",
            "October",
            "November",
            "December",
          ],
          datasets: [
            {
              backgroundColor: "green",
              label: "Profit(₦)",
              data: [0, 0, 0, 4000, 40000, 0, 0, 2000, 30000, 0, 0, 0],
            },
            {
              backgroundColor: "red",
              label: "Expenses(₦)",
              data: [
                0,
                0,
                0,
                10000,
                20000,
                30000,
                40000,
                50000,
                60000,
                0,
                0,
                0,
              ],
            },
          ],
        },
        chart_options: {
          scales: {
            yAxes: [
              {
                ticks: {
                  min: 0,
                },
                scaleLabel: {
                  display: true,
                  labelString: "Amount in Naira",
                },
              },
            ],
          },
          responsive: true,
          maintainAspectRatio: false,
        },
      };
    },
    watch: {
      getProfitAndExpenses(newValue) {
        newValue.totalProfit.forEach((profitItem, index) => {
          this.chart_data.datasets[0].data[index] = profitItem.totalProfit;
          this.chart_data.datasets[1].data[index] =
            newValue.totalExpenses[index].totalExpenses;
        });
        this.renderChart(this.chart_data, this.chart_options);
      },
    },
    computed: {
      loadingState: function() {
        return this.$apollo.queries.getProfitAndExpenses.loading;
      },
    },

    methods: {},
    created() {},
    mounted() {
      this.renderChart(this.chart_data, this.chart_options);
    },
  };
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  #bar-chart {
    margin-left: auto;
    margin-right: auto;
  }
</style>
