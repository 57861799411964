<template>
  <div>
    <div>
      <div
        class="bg-darkblue z-50 pt-4 pb-4 flex w-full pl-8 pr-8 fixed justify-between"
      >
        <div>
          <h1
            class="text-white font-semibold inline-block align-middle mr-7 text-lg"
          >
            <router-link class="text-white xl:text-base" to="/app">
              {{ appName }}
            </router-link>
          </h1>
          <button
            @click="ToggleMenu"
            id="toggler1"
            class="cursor-pointer toggler align-middle hidden md:inline-block inline-block focus:ring-2 focus:ring-green-400"
            type="button "
          >
            <div class="ml-auto mr-auto w-6 h-0.5 mt-1 bg-lightblue"></div>
            <div class="w-6 ml-auto mr-auto h-0.5 mt-1 bg-lightblue"></div>
            <div class="w-6 ml-auto mr-auto h-0.5 mt-1 bg-lightblue"></div>
          </button>
        </div>
        <div class="flex">
          <ul class="h-full">
            <li class="inline-block text-white h-full">
              <button
                @click="Logout"
                class="cursor-pointer xl:text-sm align-middle h-full"
                type="button "
              >
                <img class="w-6 h-auto inline-block" :src="icons.logout" />
                <span class="hidden md:inline-block">Logout</span>
              </button>
            </li>
            <li class="ml-4 inline-block md:hidden text-white h-full">
              <button
                @click="ToggleMenu"
                id="toggler2"
                class="cursor-pointer toggler align-middle focus:ring-2 focus:ring-green-400"
                type="button "
              >
                <div
                  class="ml-auto mr-auto w-4 md:w-6 h-0.5 mt-1 bg-lightblue"
                ></div>
                <div
                  class="w-4 md:w-6 ml-auto mr-auto h-0.5 mt-1 bg-lightblue"
                ></div>
                <div
                  class="w-4 md:w-6 ml-auto mr-auto h-0.5 mt-1 bg-lightblue"
                ></div>
              </button>
            </li>
          </ul>
        </div>
      </div>
      <div class="d-flex">
        <div
          id="sidebar"
          :class="{
            'fortunate-sidebar-closed': toggle_menu,
            'fortunate-sidebar': !toggle_menu,
          }"
          class="bg-lightblue h-screen pt-16 fixed z-30 overflow-y-scroll"
        >
          <ul class="p-6">
            <li class="text-darkblue hover:font-bold cursor-pointer mb-4">
              <router-link class="block" to="/app">
                <img
                  class="w-5 h-auto mr-2 inline align-middle"
                  :src="icons.home"
                />
                <span class="align-middle md:text-xs">Overview</span>
              </router-link>
            </li>
            <li
              v-if="
                appPermissions.invoice
                  ? appPermissions.invoice.read === true
                  : false
              "
              class="text-darkblue hover:font-bold cursor-pointer mb-4"
            >
              <router-link class="block" to="/app/invoices">
                <img
                  class="w-5 h-auto mr-2 inline align-middle"
                  :src="icons.file"
                />
                <span class="align-middle md:text-xs">Invoice</span>
              </router-link>
            </li>
            <li
              v-if="
                appPermissions.statement
                  ? appPermissions.statement.read === true
                  : false
              "
              class="text-darkblue hover:font-bold cursor-pointer mb-4"
            >
              <router-link class="block" to="/app/statement">
                <img
                  class="w-5 h-auto mr-2 inline align-middle"
                  :src="icons.file"
                />
                <span class="align-middle md:text-xs">Statement</span>
              </router-link>
            </li>
            <li
              v-if="
                appPermissions.purchase
                  ? appPermissions.purchase.read === true
                  : false
              "
              class="text-darkblue hover:font-bold cursor-pointer mb-4"
            >
              <router-link class="block" to="/app/purchases">
                <font-awesome-icon
                  title="Stock"
                  :class="' text-sm mr-2'"
                  :icon="['fa', 'shopping-basket']"
                />
                <span class="align-middle md:text-xs">Purchases</span>
              </router-link>
            </li>
            <li
              v-if="
                appPermissions.stock
                  ? appPermissions.stock.read === true
                  : false
              "
              class="text-darkblue hover:font-bold cursor-pointer mb-4"
            >
              <router-link class="block" to="/app/stock">
                <font-awesome-icon
                  title="Stock"
                  :class="' text-sm mr-2'"
                  :icon="['fa', 'archive']"
                />
                <span class="align-middle md:text-xs">Stock</span>
              </router-link>
            </li>
            <li
              v-if="role.toLowerCase() == 'admin'"
              class="text-darkblue hover:font-bold cursor-pointer mb-4"
            >
              <router-link class="block" to="/app/analytics">
                <img
                  class="w-5 h-auto mr-2 inline align-middle"
                  :src="icons.zap"
                />
                <span class="align-middle md:text-xs">Analytics</span>
              </router-link>
            </li>

            <li
              v-if="
                appPermissions.customer
                  ? appPermissions.customer.read === true
                  : false
              "
              class="text-darkblue hover:font-bold cursor-pointer mb-4"
            >
              <router-link class="block" to="/app/customers">
                <img
                  class="w-5 h-auto mr-2 inline align-middle"
                  :src="icons.users"
                />
                <span class="align-middle md:text-xs">Customers</span>
              </router-link>
            </li>
            <li
              v-if="
                appPermissions.expense
                  ? appPermissions.statement.read === true
                  : false
              "
              class="text-darkblue hover:font-bold cursor-pointer mb-4"
            >
              <router-link class="block" to="/app/expenses">
                <font-awesome-icon
                  title="Expenses"
                  :class="' text-sm mr-2'"
                  :icon="['fa', 'credit-card']"
                />
                <span class="align-middle md:text-xs">Expenses</span>
              </router-link>
            </li>
            <li
              v-if="
                appPermissions.expenseitem
                  ? appPermissions.expenseitem.read === true
                  : false
              "
              class="text-darkblue hover:font-bold cursor-pointer mb-4"
            >
              <router-link class="block" to="/app/expenseItems">
                <img
                  class="w-5 h-auto mr-2 inline align-middle"
                  :src="icons.pen"
                />
                <span class="align-middle md:text-xs">Expense Items</span>
              </router-link>
            </li>
            <li
              v-if="
                appPermissions.partner
                  ? appPermissions.partner.read === true
                  : false
              "
              class="text-darkblue hover:font-bold cursor-pointer mb-4"
            >
              <router-link class="block" disabled to="/app/partners">
                <img
                  class="w-5 h-auto mr-2 inline align-middle"
                  :src="icons.users"
                />
                <span class="align-middle md:text-xs">Partners</span>
              </router-link>
            </li>
            <li
              v-if="
                appPermissions.partner
                  ? appPermissions.partner.read === true
                  : false
              "
              class="text-darkblue hover:font-bold cursor-pointer mb-4"
            >
              <router-link class="block" disabled to="/app/sellers">
                <img
                  class="w-5 h-auto mr-2 inline align-middle"
                  :src="icons.users"
                />
                <span class="align-middle md:text-xs">Sellers</span>
              </router-link>
            </li>
            <li
              v-if="
                appPermissions.quote
                  ? appPermissions.quote.read === true
                  : false
              "
              class="text-darkblue hover:font-bold cursor-pointer mb-4"
            >
              <router-link class="block" to="/app/quotes">
                <img
                  class="w-5 h-auto mr-2 inline align-middle"
                  :src="icons.users"
                />
                <span class="align-middle md:text-xs">Quotes</span>
              </router-link>
            </li>
            <li
              v-if="
                appPermissions.vehicle
                  ? appPermissions.vehicle.read === true
                  : false
              "
              class="text-darkblue hover:font-bold cursor-pointer mb-4"
            >
              <router-link class="block" to="/app/vehicles">
                <font-awesome-icon
                  title="Stock"
                  :class="' text-sm mr-2'"
                  :icon="['fa', 'car']"
                />
                <span class="align-middle md:text-xs">Vehicles</span>
              </router-link>
            </li>
            <li
              v-if="
                appPermissions.vehicletrip
                  ? appPermissions.vehicletrip.read === true
                  : false
              "
              class="text-darkblue hover:font-bold cursor-pointer mb-4"
            >
              <router-link class="block" to="/app/vehicle-trips">
                <font-awesome-icon
                  title="Stock"
                  :class="' text-sm mr-2'"
                  :icon="['fa', 'map']"
                />
                <span class="align-middle md:text-xs">Vehicle Trips</span>
              </router-link>
            </li>
            <li
              v-if="
                appPermissions.role ? appPermissions.role.read === true : false
              "
              class="text-darkblue hover:font-bold cursor-pointer mb-4"
            >
              <router-link class="block" to="/app/roles">
                <img
                  class="w-5 h-auto mr-2 inline align-middle"
                  :src="icons.users"
                />
                <span class="align-middle md:text-xs">Roles</span>
              </router-link>
            </li>
            <li
              v-if="
                appPermissions.user ? appPermissions.user.read === true : false
              "
              class="text-darkblue hover:font-bold cursor-pointer mb-4"
            >
              <router-link class="block" to="/app/staff">
                <img
                  class="w-5 h-auto mr-2 inline align-middle"
                  :src="icons.users"
                />
                <span class="align-middle md:text-xs">Users</span>
              </router-link>
            </li>
            <li class="text-darkblue hover:font-bold cursor-pointer mb-4">
              <router-link class="block" to="/app/settings">
                <img
                  class="w-5 h-auto mr-2 inline align-middle"
                  :src="icons.settings"
                />
                <span class="align-middle md:text-xs">Settings</span>
              </router-link>
            </li>
          </ul>
        </div>
        <div
          id="main-body"
          :class="{
            'fortunate-main-closed': toggle_menu,
            'fortunate-main': !toggle_menu,
          }"
          class="d-flex z-10 bg-dashboardbody min-h-screen h-full pt-20"
        >
          <div class="ml-3 md:ml-7 md:mr-7 mr-3 min-h-screen">
            <div class="fixed z-40 w-11/12 md:w-9/12 pl-2 pr-2 pt-2">
              <t-alert
                @hidden="AlertHidden"
                :fixedClasses="alertConfig.fixedClasses"
                :classes="alertConfig.classes"
                :timeout="alertTimeout"
                :variants="alertConfig.variants"
                :variant="alertVariant"
                :show="showAlert"
              >
                <div v-if="Array.isArray(alertMessage)">
                  <p>{{ alertTitle }}</p>
                  <ul>
                    <ol v-for="(msg, index) in alertMessage" :key="index">
                      {{
                        msg
                      }}
                    </ol>
                  </ul>
                </div>
                <span v-else> {{ alertMessage }}</span>
              </t-alert>
            </div>

            <router-view @show-alert="ShowAlert($event)"> </router-view>
          </div>
          <div class="text-center pt-3 pb-3 mt-5 w-full bg-darkblue">
            <p class="text-white">Copyright 2021 Fortunate Steel</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapMutations, mapState } from "vuex";
  import { permissionMixin } from "@/assets/helpers/permissionMixin";
  import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

  export default {
    name: "AppContainer",
    mixins: [permissionMixin],

    components: {
      FontAwesomeIcon,
    },
    data() {
      return {
        show_filter: false,
        toggle_menu: false,
        showAlert: false,
        appName: process.env.VUE_APP_NAME,

        alertMessage: "",
        alertTimeout: null,
        alertVariant: "success",
        alertConfig: {
          fixedClasses: {
            wrapper:
              "relative flex md:text-xs items-center p-4 border-l-4  rounded shadow-sm",
            body: "flex-grow",
            close:
              "absolute relative flex items-center justify-center ml-4 flex-shrink-0 w-6 h-6 transition duration-100 ease-in-out rounded focus:ring-2 focus:ring-blue-500 focus:outline-none focus:ring-opacity-50",
            closeIcon: "fill-current h-4 w-4",
          },
          classes: {
            wrapper: "bg-blue-50 border-blue-500",
            body: "text-blue-700",
            close: "text-blue-500 hover:bg-blue-200",
          },
          variants: {
            error: {
              wrapper: "bg-red-50 border-red-500",
              body: "text-red-700",
              close: "text-red-500 hover:bg-red-200",
            },
            success: {
              wrapper: "bg-green-50 border-green-500",
              body: "text-green-700",
              close: "text-green-500 hover:bg-green-200",
            },
            notification: {
              wrapper: "bg-blue-50 border-blue-500",
              body: "text-blue-700",
              close: "text-blue-500 hover:bg-blue-200",
            },
          },
        },
        icons: {
          blue_card: require("../assets/imgs/icons/blue-cardbg.svg"),
          red_card: require("../assets/imgs/icons/red-cardbg.svg"),
          logout: require("../assets/imgs/icons/logout.svg"),
          filter: require("../assets/imgs/icons/filter-filled.svg"),
          home: require("../assets/imgs/icons/home.svg"),
          pen: require("../assets/imgs/icons/pen.svg"),
          file: require("../assets/imgs/icons/file.svg"),
          users: require("../assets/imgs/icons/users.svg"),
          trash: require("../assets/imgs/icons/trash.svg"),
          settings: require("../assets/imgs/icons/settings.svg"),
          receipt: require("../assets/imgs/icons/receipt.svg"),
          zap: require("../assets/imgs/icons/zap.svg"),
        },
      };
    },
    computed: {
      ...mapState({
        role: "role",
      }),
    },
    methods: {
      ...mapMutations(["SetToken"]),
      AlertHidden() {
        this.showAlert = false;
        this.alertVariant = null;
        this.alertMessage = null;
        this.alertTimeout = null;
      },
      Logout() {
        this.SetToken(null);
        this.$router.push("/");
      },
      ToggleMenu() {
        this.toggle_menu = !this.toggle_menu;
      },
      ShowFilter() {
        this.show_filter = !this.show_filter;
      },
      ShowAlert($event) {
        this.alertTitle = $event.title;
        this.alertVariant = $event.type;
        this.alertMessage = $event.message;
        this.showAlert = true;
        if ($event.timeout) {
          this.alertTimeout = $event.timeout;
        }
      },
      ShowEvent() {
        this.$emit("show-alert", { type: "success", message: "too lit" });
      },
      HideFilter() {
        if (this.show_filter == false) return;
        this.show_filter = false;
      },
    },
    mounted() {},
  };
</script>

<style>
  .router-link-exact-active {
    font-weight: bold;
  }
</style>
