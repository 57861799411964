<template>
  <div id="app">
    <div class="fixed w-full pl-2 pr-2 pt-2">
      <t-alert
        @hidden="AlertHidden"
        :fixedClasses="alertConfig.fixedClasses"
        :classes="alertConfig.classes"
        :variants="alertConfig.variants"
        :variant="alertVariant"
        :show="showAlert"
      >
        {{ alertMessage }}
      </t-alert>
    </div>
    <router-view></router-view>
  </div>
</template>

<script>
  // import Dashboard from "./Views/Dashboard.vue";

  export default {
    name: "App",
    components: {
      // Dashboard,
    },
    data() {
      return {
        show_filter: false,
        toggle_menu: false,
        showAlert: false,
        alertMessage: "alert",
        alertVariant: "success",
        alertConfig: {
          fixedClasses: {
            wrapper:
              "relative flex items-center p-4 border-l-4  rounded shadow-sm",
            body: "flex-grow",
            close:
              "absolute relative flex items-center justify-center ml-4 flex-shrink-0 w-6 h-6 transition duration-100 ease-in-out rounded focus:ring-2 focus:ring-blue-500 focus:outline-none focus:ring-opacity-50",
            closeIcon: "fill-current h-4 w-4",
          },
          classes: {
            wrapper: "bg-blue-50 border-blue-500",
            body: "text-blue-700",
            close: "text-blue-500 hover:bg-blue-200",
          },
          variants: {
            danger: {
              wrapper: "bg-red-50 border-red-500",
              body: "text-red-700",
              close: "text-red-500 hover:bg-red-200",
            },
            success: {
              wrapper: "bg-green-50 border-green-500",
              body: "text-green-700",
              close: "text-green-500 hover:bg-green-200",
            },
          },
        },
        icons: {
          blue_card: require("./assets/imgs/icons/blue-cardbg.svg"),
          red_card: require("./assets/imgs/icons/red-cardbg.svg"),
          logout: require("./assets/imgs/icons/logout.svg"),
          filter: require("./assets/imgs/icons/filter-filled.svg"),
          home: require("./assets/imgs/icons/home.svg"),
          pen: require("./assets/imgs/icons/pen.svg"),
          file: require("./assets/imgs/icons/file.svg"),
          users: require("./assets/imgs/icons/users.svg"),
          trash: require("./assets/imgs/icons/trash.svg"),
          settings: require("./assets/imgs/icons/settings.svg"),
          receipt: require("./assets/imgs/icons/receipt.svg"),
          zap: require("./assets/imgs/icons/zap.svg"),
        },
      };
    },
    methods: {
      AlertHidden() {
        this.showAlert = false;
        this.alertVariant = null;
        this.alertMessage = null;
      },
      ToggleMenu() {
        this.toggle_menu = !this.toggle_menu;
      },
      ShowAlert(alertData) {
        this.alertVariant = alertData.type;
        this.alertMessage = alertData.message;
      },
      ShowFilter() {
        this.show_filter = !this.show_filter;
      },
      HideFilter() {
        if (this.show_filter == false) return;
        this.show_filter = false;
      },
    },
  };
</script>

<style>
  @import "./assets/css/custom.css";
  .router-link-exact-active {
    font-weight: bold;
  }
</style>
