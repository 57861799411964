import { mapState } from "vuex";

export const permissionMixin = {
  computed: {
    appPermissions: function() {
      const appPermissions = {};
      this.permissions.forEach((permission) => {
        appPermissions[`${permission.label.toLowerCase()}`] = {
          create: permission.create,
          edit: permission.edit,
          read: permission.read,
          delete: permission.delete,
        };
      });
      return appPermissions;
    },
    ...mapState({
      permissions: "permissions",
    }),
  },
  methods: {
    ShowAlert($event) {
      this.$emit("show-alert", {
        type: $event.type,
        message: $event.message,
      });
    },
  },
};
