<template>
  <div
    class="flex fortunate-card-container justify-between items-center flex-col md:flex-row  "
  >
    <div
      :style="{ backgroundImage: `url('${icons.blue_card}')` }"
      class="mb-4 md:mb-0 w-64 h-32 rounded-lg fortunate-card justify-around items-center flex"
    >
      <img :src="icons.receipt" class="w-16 h-auto" alt="" />
      <div class="text-white">
        <span>{{ card_data[0].label }}</span>
        <h3 class="text-2xl">{{ card_data[0].value }}</h3>
      </div>
    </div>
    <div
      :style="{ backgroundImage: `url('${icons.red_card}')` }"
      class="mb-4 mr-2 ml-2 w-64 md:mb-0 h-32  rounded-lg fortunate-card justify-around items-center flex"
    >
      <img :src="icons.receipt" class="w-16 h-auto" alt="" />
      <div class="text-white">
        <span>{{ card_data[1].label }}</span>
        <h3 class="text-2xl">{{ card_data[1].value }}</h3>
      </div>
    </div>
    <div
      :style="{ backgroundImage: `url('${icons.blue_card}')` }"
      class="mb-4 w-64 h-32 md:mb-0 rounded-lg fortunate-card justify-around items-center flex"
    >
      <img :src="icons.receipt" class="w-16 h-auto" alt="" />
      <div class="text-white">
        <span>{{ card_data[2].label }}</span>
        <h3 class="text-2xl">{{ card_data[1].value }}</h3>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: "Cards",
    props: {
      msg: String,
      card_data: Array,
    },
    data() {
      return {
        icons: {
          blue_card: require("../assets/imgs/icons/blue-cardbg.svg"),
          red_card: require("../assets/imgs/icons/red-cardbg.svg"),

          receipt: require("../assets/imgs/icons/receipt.svg"),
        },
      };
    },
    computed: {},
    methods: {},
    mounted() {
      // alert("inner" + window.innerWidth + ", Outer" + window.innerWidth);
    },
  };
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .fortunate-card {
    background-repeat: no-repeat;
    background-size: cover;
  }
</style>
