import Vue from "vue";
import Vuex from "vuex";
import Axios from "axios";
import VueAxios from "vue-axios";
import createPersistedState from "vuex-persistedstate";
// import router from './router'

Vue.use(Vuex);
Vue.use(VueAxios, Axios);

export default new Vuex.Store({
  plugins: [createPersistedState()],

  state: {
    toggleNav: false,
    token: "",
    role: "",
    breadcrumbs: [],
    permissions: {},
  },
  mutations: {
    sideBarToggle(state, payload) {
      state.toggleNav = payload;
    },
    setBreadcrumbs(state, payload) {
      state.breadcrumbs = payload;
    },
    SetToken(state, payload) {
      state.token = payload;
    },
    SetRole(state, payload) {
      state.role = payload;
    },
    SetPermissions(state, payload) {
      state.permissions = payload;
    },
  },
  actions: {
    // GetSingleCustomer({ commit }, payload) {
    //   const url = `${process.env.VUE_APP_APIURL}/customers/single/${payload}`;
    //   Axios.get(url)
    //     .then((response) => {
    //       const data = response.data;
    //       const customer = {
    //         _id: data._id,
    //         name: data.personal_info.name,
    //         phone_number: data.personal_info.phone_number,
    //         email: data.personal_info.email,
    //         address: data.personal_info.address,
    //         vehicle_count: data.vehicles.length,
    //         vehicles: data.vehicles,
    //         reg_date: data.personal_info.reg_date,
    //       };
    //       commit("SetCustomerInfo", customer);
    //       this._vm.$preloaders.close();
    //     })
    //     .catch(() => {
    //       commit("SetAlert", {
    //         message:
    //           "Error retrieving Customer and Vehicles from database,please check your Network or contact your developer",
    //         error: true,
    //         display: true,
    //       });
    //       this._vm.$preloaders.close();
    //     });
    // },
  },
  getters: {},
});
