<template>
  <div
    class=" flex bg-lightblue flex-col h-screen md:flex-row items-center justify-center mb-3"
  >
    <div class=" w-11/12 md:w-4/12 bg-white  p-6 rounded-lg ">
      <h1 class="font-bold text-lg text-center text-darkblue">
        {{ appName }}
      </h1>
      <ValidationObserver v-slot="{ handleSubmit }">
        <form @submit.prevent="handleSubmit(Login)">
          <div class="mb-4">
            <label class="block" for="seller_name">Email or Phone Number</label>
            <validation-provider rules="required" v-slot="{ errors }">
              <input
                type="text"
                id="login"
                v-model="login"
                placeholder="Enter your email or phone number"
                class="border-2 w-full border-darkblue rounded-lg block"
              />
              <span class="text-red-700">{{ errors[0] }}</span>
            </validation-provider>
          </div>
          <div class="mb-4">
            <label class="block" for="seller_name">Password</label>
            <validation-provider rules="required" v-slot="{ errors }">
              <input
                :type="!!showPassword ? 'text' : 'password'"
                id="password"
                autocomplete="on"
                v-model="password"
                placeholder="Enter your password"
                class="border-2 w-full border-darkblue rounded-lg block"
              />
              <span class="text-red-700">{{ errors[0] }}</span>
            </validation-provider>
          </div>
          <div class=" flex justify-between mb-3">
            <div>
              <input
                v-model="showPassword"
                type="checkbox"
                name=""
                class="mr-1"
                id="showPassword"
              />
              <label class="text-xs mr-1" for="showPassword">
                Show password
              </label>
            </div>
            <div>
              <router-link class="text-darkblue text-xs" to="/forgotPassword"
                >Forgot Password?
              </router-link>
            </div>
          </div>
          <button
            type="submit"
            class="text-white block spinner bg-darkblue hover:bg-black pl-6 pt-2 pb-2 pr-6 ml-auto mr-auto rounded-full"
          >
            <svg
              v-if="loadingSpinner"
              class="inline animate-spin mr-2"
              width="18"
              height="18"
              viewBox="0 0 50 50"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M48 25C48 12.2975 37.7025 2 25 2"
                stroke="black"
                stroke-width="4"
              />
              <path
                d="M48 25C48 37.7025 37.7025 48 25 48"
                stroke="white"
                stroke-width="4"
              />
              <path
                d="M2 25C2 12.2975 12.2975 2 25 2"
                stroke="white"
                stroke-width="4"
              />
              <path
                d="M25 48C12.2975 48 2 37.7025 2 25"
                stroke="#C9000C"
                stroke-width="4"
              />
            </svg>

            <span>Login to Fortunate Steel</span>
          </button>
          <p v-if="loginError" class="text-red-700 capitalize text-center mt-4">
            {{ loginError }}
          </p>
        </form>
      </ValidationObserver>
    </div>
  </div>
</template>

<script>
  import gql from "graphql-tag";
  const jwt = require("jsonwebtoken");
  import { mapMutations, mapState } from "vuex";
  import { ValidationProvider, ValidationObserver, extend } from "vee-validate";
  import { required } from "vee-validate/dist/rules";
  extend("required", {
    ...required,
    message: "This field is required",
  });
  export default {
    name: "Login",
    props: {},
    components: {
      ValidationProvider,
      ValidationObserver,
    },

    data() {
      return {
        login: "",
        appName: process.env.VUE_APP_NAME,

        showPassword: false,
        loginError: null,
        password: "",
        loadingSpinner: false,
      };
    },
    computed: {
      ...mapState({
        role: "role",
        token: "token",
      }),
    },
    methods: {
      ...mapMutations([
        "SetToken",
        "SetPermissions",
        "SetRole",
        "setBreadcrumbs",
      ]),
      async Login() {
        this.loadingSpinner = true;
        this.loginError = null;
        try {
          const { data, errors } = await this.$apollo.mutate({
            // Query
            mutation: gql`
              mutation loginUser($login: String!, $password: String!) {
                loginUser(user: { login: $login, password: $password }) {
                  ... on LoggedinUser {
                    _id
                    token
                  }
                  ... on AlertInterface {
                    classification
                    payload
                    type
                  }
                }
              }
            `,
            // Parameters
            variables: {
              login: this.login,
              password: this.password,
            },
          });

          if (errors && data == null) {
            this.loadingSpinner = false;
            return this.$emit("show-alert", {
              type: "error",
              message: errors[0].message,
            });
          }

          try {
            const decoded = jwt.verify(
              data.loginUser.token,
              process.env.VUE_APP_JWT_SECRET
            );
            this.SetToken(data.loginUser.token);
            this.SetPermissions(decoded.role.permissions);
            this.SetRole(decoded.role.name);
          } catch (err) {
            this.$emit("show-alert", {
              type: "error",
              message: "invalid login token,please contact your developer",
            });
            this.loadingSpinner = false;
            return;
          }
          this.SetToken(data.loginUser.token);
          this.loadingSpinner = false;
          this.$router.push("/app");
        } catch (error) {
          this.loadingSpinner = false;
          this.$emit("show-alert", {
            type: "error",
            message:
              "server error,please check your network or contact your developer",
          });
        }
      },
    },
    mounted() {
      this.setBreadcrumbs([]);
      if (this.$route.query.sessionExpired === "true") {
        this.$emit("show-alert", {
          type: "error",
          message: "Your session has expired ,please login",
        });
      }
      if (this.token) {
        this.$router.push("/app");
      }
    },
  };
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  table {
    border-collapse: separate;
    border-spacing: 0 0.5rem;
  }
</style>
