<template>
  <div class="relative min-h-screen">
    <div class="fixed z-40 w-full l-0 r-0 pl-2 pr-2 pt-2 absolute">
      <t-alert
        @hidden="AlertHidden"
        :fixedClasses="alertConfig.fixedClasses"
        :classes="alertConfig.classes"
        :timeout="alertTimeout"
        :variants="alertConfig.variants"
        :variant="alertVariant"
        :show="showAlert"
      >
        <div v-if="Array.isArray(alertMessage)">
          <p>{{ alertTitle }}</p>
          <ul class="list-disc">
            <ol v-for="(msg, index) in alertMessage" :key="index">
              {{
                msg
              }}
            </ol>
          </ul>
        </div>
        <span v-else> {{ alertMessage }}</span>
      </t-alert>
    </div>

    <BreadCrumb class="mb-4" />

    <router-view @show-alert="ShowAlert($event)"></router-view>
  </div>
</template>

<script>
  import BreadCrumb from "../components/BreadCrumb.vue";

  export default {
    name: "Wrapper",
    props: {},
    components: {
      BreadCrumb,
    },
    data() {
      return {
        showAlert: false,
        alertMessage: "",
        alertTimeout: null,
        alertVariant: "success",
        alertConfig: {
          fixedClasses: {
            wrapper:
              "relative flex md:text-xs items-center p-4 border-l-4  rounded shadow-sm",
            body: "flex-grow",
            close:
              "absolute relative flex items-center justify-center ml-4 flex-shrink-0 w-6 h-6 transition duration-100 ease-in-out rounded focus:ring-2 focus:ring-blue-500 focus:outline-none focus:ring-opacity-50",
            closeIcon: "fill-current h-4 w-4",
          },
          classes: {
            wrapper: "bg-blue-50 border-blue-500",
            body: "text-blue-700",
            close: "text-blue-500 hover:bg-blue-200",
          },
          variants: {
            error: {
              wrapper: "bg-red-50 border-red-500",
              body: "text-red-700",
              close: "text-red-500 hover:bg-red-200",
            },
            success: {
              wrapper: "bg-green-50 border-green-500",
              body: "text-green-700",
              close: "text-green-500 hover:bg-green-200",
            },
            notification: {
              wrapper: "bg-blue-50 border-blue-500",
              body: "text-blue-700",
              close: "text-blue-500 hover:bg-blue-200",
            },
          },
        },
      };
    },
    computed: {},
    methods: {
      AlertHidden() {
        this.showAlert = false;
        this.alertVariant = null;
        this.alertMessage = null;
        this.alertTimeout = null;
      },
      ShowAlert($event) {
        this.alertTitle = $event.title;

        this.alertVariant = $event.type;
        this.alertMessage = $event.message;
        this.showAlert = true;
        if ($event.timeout) {
          this.alertTimeout = $event.timeout;
        }
      },
    },
    mounted() {},
  };
</script>

<style scoped></style>
