import Vue from "vue";
import Store from "./store";
import AppContainer from "./views/AppContainer.vue";
import Router from "vue-router";
import Overview from "./views/Overview.vue";
const Analytics = () => import("./views/Analytics.vue");
const Stock = () => import("./views/Stock/Stock.vue");
const NotFound = () => import("./views/NotFound.vue");
const SingleStock = () => import("./views/Stock/SingleStock.vue");
const ManageStock = () => import("./views/Stock/ManageStock.vue");
const ManagePurchase = () => import("./views/Purchases/ManagePurchase.vue");
const Purchases = () => import("./views/Purchases/Purchases.vue");
const SingleSeller = () => import("./views/Sellers/SingleSeller.vue");
const ManageSeller = () => import("./views/Sellers/ManageSeller.vue");
const Sellers = () => import("./views/Sellers/Sellers.vue");
const SinglePurchase = () => import("./views/Purchases/SinglePurchase.vue");
const ManageExpenses = () => import("./views/Expenses/ManageExpenses.vue");
const Expenses = () => import("./views/Expenses/Expenses.vue");
const SingleExpense = () => import("./views/Expenses/SingleExpense.vue");
const SingleRole = () => import("./views/Roles/SingleRole.vue");
const ManageRole = () => import("./views/Roles/ManageRole.vue");
const Roles = () => import("./views/Roles/Roles.vue");
const ManageUser = () => import("./views/Users/ManageUser.vue");
const SingleUser = () => import("./views/Users/SingleUser.vue");
const Users = () => import("./views/Users/Users.vue");
const Customers = () => import("./views/Customers/Customers.vue");
const SingleCustomer = () => import("./views/Customers/SingleCustomer.vue");
const ManageCustomer = () => import("./views/Customers/ManageCustomer.vue");
const ManageExpenseItem = () =>
  import("./views/ExpenseItems/ManageExpenseItem.vue");
const SingleExpenseItem = () =>
  import("./views/ExpenseItems/SingleExpenseItem.vue");
const ExpenseItems = () => import("./views/ExpenseItems/ExpenseItems.vue");
const ManageQuote = () => import("./views/Quotes/ManageQuote.vue");
const Quotes = () => import("./views/Quotes/Quotes.vue");
const SingleQuote = () => import("./views/Quotes/SingleQuote.vue");
const ManageInvoice = () => import("./views/Invoice/ManageInvoice.vue");
const SingleInvoice = () => import("./views/Invoice/SingleInvoice.vue");
const Invoices = () => import("./views/Invoice/Invoices.vue");
const ManageStatement = () => import("./views/Statements/ManageStatement.vue");
const SingleStatement = () => import("./views/Statements/SingleStatement.vue");
const Statement = () => import("./views/Statements/Statement.vue");
const SinglePartner = () => import("./views/Partners/SinglePartner.vue");
const ManagePartner = () => import("./views/Partners/ManagePartner.vue");
const Partners = () => import("./views/Partners/Partners.vue");

// vehicles
const Vehicle = () => import("./views/Vehicles/Vehicle.vue");
const ManageVehicle = () => import("./views/Vehicles/ManageVehicle.vue");
const SingleVehicle = () => import("./views/Vehicles/SingleVehicle.vue");

// vehicleTrip
const VehicleTrip = () => import("./views/VehicleTrips/VehicleTrip.vue");
const ManageVehicleTrip = () =>
  import("./views/VehicleTrips/ManageVehicleTrip.vue");
const SingleVehicleTrip = () =>
  import("./views/VehicleTrips/SingleVehicleTrip.vue");

const Settings = () => import("./views/Settings.vue");
const ForgotPassword = () => import("./views/ForgotPassword.vue");
const ResetPassword = () => import("./views/ResetPassword.vue");
import Login from "./views/Login.vue";
import Wrapper from "./views/Wrapper.vue";

// import NotFound from "./views/404.vue";

Vue.use(Router);

const router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    {
      path: "*",
      name: "404",
      component: NotFound,
    },

    {
      path: "/",
      component: Wrapper,
      children: [
        {
          path: "",
          name: "login",
          component: Login,
        },
      ],
    },
    {
      path: "/forgotPassword",
      component: Wrapper,
      children: [
        {
          path: "",
          name: "forgotPassword",
          component: ForgotPassword,
        },
      ],
    },
    {
      path: "/resetPassword/:resetToken",
      component: Wrapper,
      children: [
        {
          path: "",
          name: "resetPassword",
          component: ResetPassword,
        },
      ],
    },

    {
      path: "/app",
      component: AppContainer,
      meta: {
        requiresAuth: true,
      },
      children: [
        {
          path: "",
          name: "overview",
          component: Overview,
        },

        {
          path: "analytics",
          name: "analytics",
          component: Analytics,
          meta: {
            requiresAdmin: true,
          },
        },
        {
          path: "stock",
          component: Wrapper,
          children: [
            {
              path: "",
              name: "stock",
              component: Stock,
              meta: {
                model: "Stock",
                permission: "read",
              },
            },
            {
              path: ":id",
              name: "singleStock",
              component: SingleStock,
              meta: {
                model: "Stock",
                permission: "read",
              },
            },
            {
              path: "manage/:mode/:id?",
              name: "manageStock",
              component: ManageStock,
              meta: {
                model: "Stock",
                permission: "edit",
              },
            },
          ],
        },
        {
          path: "customers",
          component: Wrapper,
          children: [
            {
              path: "",
              name: "customers",
              component: Customers,
              meta: {
                model: "Customer",
                permission: "read",
              },
            },
            {
              path: "manage/:mode/:id?",
              name: "manage_customer",
              component: ManageCustomer,
              meta: {
                model: "Customer",
                permission: "edit",
              },
            },
            {
              path: ":id",
              name: "single_customer",
              component: SingleCustomer,
              meta: {
                model: "Customer",
                permission: "read",
              },
            },
          ],
        },
        {
          path: "staff",
          component: Wrapper,
          children: [
            {
              path: "",
              name: "staff",
              component: Users,
              meta: {
                model: "User",
                permission: "read",
              },
            },
            {
              path: ":id",
              name: "singleUser",
              component: SingleUser,
              meta: {
                model: "User",
                permission: "read",
              },
            },
            {
              path: "staff/manage/:mode/:id?",
              name: "manageUser",
              component: ManageUser,
              meta: {
                model: "User",
                permission: "edit",
              },
            },
          ],
        },
        {
          path: "statement",
          component: Wrapper,
          children: [
            {
              path: "",
              name: "statement",
              component: Statement,
              meta: {
                model: "Statement",
                permission: "read",
              },
            },
            {
              path: ":id",
              name: "singleStatement",
              component: SingleStatement,
              meta: {
                model: "Statement",
                permission: "read",
              },
            },
            {
              path: "manage/:mode",
              name: "manageStatement",
              component: ManageStatement,
              meta: {
                model: "Statement",
                permission: "edit",
              },
            },
          ],
        },
        // vehicle
        {
          path: "vehicles",
          component: Wrapper,
          children: [
            {
              path: "",
              name: "vehicles",
              component: Vehicle,
              meta: {
                model: "Vehicle",
                permission: "read",
              },
            },
            {
              path: "manage/:mode/:id?",
              name: "manageVehicle",
              component: ManageVehicle,
              meta: {
                model: "Vehicle",
                permission: "edit",
              },
            },
            {
              path: ":id",
              name: "singleVehicle",
              component: SingleVehicle,
              meta: {
                model: "Vehicle",
                permission: "read",
              },
            },
          ],
        },

        // vehicleTrips
        {
          path: "vehicle-trips",
          component: Wrapper,
          children: [
            {
              path: "",
              name: "vehicleTrips",
              component: VehicleTrip,
              meta: {
                model: "VehicleTrip",
                permission: "read",
              },
            },
            {
              path: "manage/:mode/:id?",
              name: "manageVehicleTrip",
              component: ManageVehicleTrip,
              meta: {
                model: "VehicleTrip",
                permission: "edit",
              },
            },
            {
              path: ":id",
              name: "singleVehicleTrip",
              component: SingleVehicleTrip,
              meta: {
                model: "VehicleTrip",
                permission: "read",
              },
            },
          ],
        },
        {
          path: "expenses",
          component: Wrapper,
          children: [
            {
              path: "",
              name: "expenses",
              component: Expenses,
              meta: {
                model: "Expense",
                permission: "read",
              },
            },
            {
              path: "manage/:mode/:id?",
              name: "manageExpense",
              component: ManageExpenses,
              meta: {
                model: "Expense",
                permission: "edit",
              },
            },
            {
              path: ":id",
              name: "singleExpense",
              component: SingleExpense,
              meta: {
                model: "Expense",
                permission: "read",
              },
            },
          ],
        },
        {
          path: "expenseItems",
          component: Wrapper,
          children: [
            {
              path: "",
              name: "expenseItems",
              component: ExpenseItems,
              meta: {
                model: "ExpenseItem",
                permission: "read",
              },
            },
            {
              path: ":id",
              name: "singleExpenseItem",
              component: SingleExpenseItem,
              meta: {
                model: "ExpenseItem",
                permission: "read",
              },
            },
            {
              path: "manage/:mode/:id?",
              name: "manageExpenseItem",
              component: ManageExpenseItem,
              meta: {
                model: "ExpenseItem",
                permission: "edit",
              },
            },
          ],
        },
        {
          path: "quotes",
          component: Wrapper,
          children: [
            {
              path: "",
              name: "quotes",
              component: Quotes,
              meta: {
                model: "Quote",
                permission: "read",
              },
            },
            {
              path: ":id",
              name: "single_quote",
              component: SingleQuote,
              meta: {
                model: "Quote",
                permission: "read",
              },
            },
            {
              path: "manage/:mode/:id?",
              name: "manage_quotes",
              component: ManageQuote,
              meta: {
                model: "Quote",
                permission: "edit",
              },
            },
          ],
        },
        {
          path: "purchases",
          component: Wrapper,
          children: [
            {
              path: "",
              name: "purchases",
              component: Purchases,
              meta: {
                model: "Purchase",
                permission: "read",
              },
            },
            {
              path: ":id",
              name: "single_purchase",
              component: SinglePurchase,
              meta: {
                model: "Purchase",
                permission: "read",
              },
            },
            {
              path: "manage/:mode/:id?",
              name: "manage_purchase",
              component: ManagePurchase,
              meta: {
                model: "Purchase",
                permission: "edit",
              },
            },
          ],
        },
        {
          path: "sellers",
          component: Wrapper,
          children: [
            {
              path: "",
              name: "sellers",
              component: Sellers,
              meta: {
                model: "Seller",
                permission: "read",
              },
            },
            {
              path: ":id",
              name: "singleSeller",
              component: SingleSeller,
              meta: {
                model: "Seller",
                permission: "read",
              },
            },
            {
              path: "manage/:mode/:id?",
              name: "manageSeller",
              component: ManageSeller,
              meta: {
                model: "Seller",
                permission: "edit",
              },
            },
          ],
        },
        {
          path: "invoices",
          component: Wrapper,
          children: [
            {
              path: "",
              name: "invoices",
              component: Invoices,
              meta: {
                model: "Invoice",
                permission: "read",
              },
            },
            {
              path: ":id",
              name: "single_invoice",
              component: SingleInvoice,
              meta: {
                model: "invoice",
                permission: "read",
              },
            },
            {
              path: "manage/:mode",
              name: "manage_invoice",
              component: ManageInvoice,
              meta: {
                model: "Invoice",
                permission: "edit",
              },
            },
          ],
        },
        {
          path: "partners",
          component: Wrapper,
          children: [
            {
              path: "",
              name: "partners",
              component: Partners,
              meta: {
                model: "Partner",
                permission: "read",
              },
            },
            {
              path: ":id",
              name: "singlePartner",
              component: SinglePartner,
              meta: {
                model: "partner",
                permission: "read",
              },
            },
            {
              path: "manage/:mode/:id?",
              name: "managePartner",
              component: ManagePartner,
              meta: {
                model: "Partner",
                permission: "edit",
              },
            },
          ],
        },
        {
          path: "roles",
          component: Wrapper,
          children: [
            {
              path: "",
              name: "roles",
              component: Roles,
              meta: {
                model: "Role",
                permission: "read",
              },
            },
            {
              path: ":id",
              name: "singleRole",
              component: SingleRole,
              meta: {
                model: "Role",
                permission: "read",
              },
            },
            {
              path: "manage/:mode/:id?",
              name: "manageRole",
              component: ManageRole,
              meta: {
                model: "Role",
                permission: "edit",
              },
            },
          ],
        },
        {
          path: "settings",
          component: Wrapper,
          children: [
            {
              path: "",
              name: "settings",
              component: Settings,
            },
          ],
        },
      ],
    },
  ],
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
});
router.beforeEach((to, from, next) => {
  // Store.commit("sideBarToggle", true);
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (!Store.state.token) {
      return next("/?sessionExpired=true");
    }
    if (to.meta.model) {
      const permissions = Store.state.permissions.find((permission) => {
        return (
          permission[to.meta.permission] == true &&
          permission["label"].toLowerCase() == to.meta.model.toLowerCase()
        );
      });

      if (!permissions) {
        return next(false);
      }
      return next();
    }

    return next();
  } else {
    next();
  }
});
// router.beforeEach((to, from, next) => {
//   if (window.screen.width <= 834) {
//     Store.commit("sideBarToggle", false);
//   }
//   next();
// });

export default router;
