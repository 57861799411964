<template>
  <div>
    <router-link
      v-for="(breadcrumb, index) in breadcrumbs"
      :key="index"
      class="text-xs mr-2 text-darkblue "
      :to="{ name: breadcrumb.pathName, params: breadcrumb.params }"
      >{{ breadcrumb.displayName }}

      <img
        v-if="breadcrumbs.length > 1 && breadcrumbs.length - 1 != index"
        height="13px"
        width="13px"
        src="../assets/imgs/icons/right-arrow.svg"
        class=" inline"
      />
    </router-link>
  </div>
</template>

<script>
  import { mapState } from "vuex";

  export default {
    name: "BreadCrumb",
    props: {
      msg: String,
      card_data: Array,
    },
    data() {
      return {};
    },
    computed: {
      ...mapState(["breadcrumbs"]),
    },
    methods: {},
    mounted() {},
  };
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
