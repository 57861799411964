export const emitEventMixin = {
  methods: {
    ShowAlert($event) {
      this.$emit("show-alert", {
        type: $event.type,
        message: $event.message,
      });
    },
  },
};
