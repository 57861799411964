<template>
  <div>
    <div class="mt-6">
      <Cards :card_data="card_data" />
    </div>
    <div v-if="role === 'Admin'" class=" mt-6 bg-white p-6 rounded-lg ">
      <BarChart
        :SetBarChartLoadingStatus="SetBarChartLoadingStatus"
        :year="barChartYear"
      />
      <div class=" flex mt-2 justify-center items-center">
        <span class="mr-4 md:text-xs "> Profit and Expenses for:</span>
        <t-dropdown
          :fixedClasses="{
            button: 'bg-darkblue md:text-xs  fortunate-dropdown',
          }"
          :text="barChartYear"
        >
          <div slot-scope="{ hide }" class="py-1 rounded-md shadow-xs">
            <span
              v-for="(year, index) in barChartYears"
              :key="index"
              @click="
                hide();
                SetBarChartYear(year.toString());
              "
              class="block cursor-pointer px-4 py-2 text-sm md:text-xs leading-5 text-gray-700 transition duration-150 ease-in-out hover:bg-gray-100 focus:outline-none focus:bg-gray-100"
              role="menuitem"
            >
              {{ year }}
            </span>
          </div>
        </t-dropdown>
      </div>
      <div
        v-if="loading.barChart === true"
        class="flex justify-center flex-col"
      >
        <div>
          <p class="text-red-700 md:text-xs  text-center font-bold  ">
            Loading,please wait...
          </p>
        </div>
        <div class="mt-2">
          <svg
            class="ml-auto mr-auto animate-spin"
            width="25"
            height="25"
            viewBox="0 0 50 50"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M48 25C48 12.2975 37.7025 2 25 2"
              stroke="black"
              stroke-width="4"
            />
            <path
              d="M48 25C48 37.7025 37.7025 48 25 48"
              stroke="white"
              stroke-width="4"
            />
            <path
              d="M2 25C2 12.2975 12.2975 2 25 2"
              stroke="white"
              stroke-width="4"
            />
            <path
              d="M25 48C12.2975 48 2 37.7025 2 25"
              stroke="#C9000C"
              stroke-width="4"
            />
          </svg>
        </div>
      </div>
    </div>
    <div class="mt-6 flex justify-between">
      <div class="font-bold text-darkblue">
        <h4 class="text-darkblue capitalize">{{ mode }}</h4>
      </div>
      <div>
        <button
          @click="TableButton"
          class="text-white font-bold text-xs  bg-darkblue md:pl-3 pl-2 pr-2 md:pr-3 pt-2 pb-2 rounded-lg"
        >
          <font-awesome-icon
            title="Add Invoice"
            :class="'text-white text-xs mr-1 '"
            :icon="['fas', 'plus']"
          />
          <span class="capitalize"> Add New {{ mode }}</span>
        </button>
      </div>
    </div>
    <div
      class="flex justify-between md:flex-row flex-col-reverse items-center mt-4"
    >
      <div>
        <form
          @submit.prevent="SearchTable"
          class="h-full flex flex-col  md:flex-row-reverse  "
          action=""
        >
          <div>
            <input
              required
              v-model="searchQuery"
              title="Please input a search phrase"
              class="w-72  md:text-xs  inline h-11 md:h-9 pl-2 pr-2 focus:ring-2 focus:ring-green-600  rounded-l-lg pt-1 pb-1"
              placeholder="    Search for .."
            /><button
              class="bg-darkblue pt-1 pb-1 mb-3 text-white hover:bg-blue-600 focus:text-darkblue focus:bg-lightblue pl-2 pr-2  h-11 md:h-9"
              type="submit"
            >
              <i class="fas fa-search "></i>
            </button>
          </div>
          <div class="md:mr-2 w-2/4 md:w-auto ">
            <t-select
              fixedClasses="inline md:text-xs h-11 md:h-9 rounded-l-lg cursor-pointer"
              placeholder="Filter"
              v-model="searchFilter"
              :options="searchFilterOptions"
              :required="true"
            ></t-select>
          </div>
        </form>
      </div>
      <div class="mb-3 md:mb-0">
        <button
          @click="ChangeTableMode('invoice')"
          class="pl-3 md:text-xs  pr-3 border-2 border-darkblue hover:bg-green-400 hover:border-green-400 pt-1 pb-1 rounded-full"
          :class="{
            'bg-darkblue text-white': invoice_mode,
            'text-darkblue  ': !invoice_mode,
          }"
          type="button"
        >
          Invoice
        </button>
        <button
          type="button"
          @click="ChangeTableMode('stock')"
          :class="{
            'bg-darkblue text-white': stock_mode,
            'text-darkblue  ': !stock_mode,
          }"
          class="ml-4 mr-4 md:text-xs  border-2 border-darkblue hover:bg-green-400 hover:border-green-400  pl-3 pr-3 pt-1 pb-1 rounded-full"
        >
          Stock
        </button>
        <button
          @click="ChangeTableMode('user')"
          :class="{
            'bg-darkblue text-white': user_mode,
            'text-darkblue  ': !user_mode,
          }"
          type="button"
          class="pl-3 md:text-xs  border-2 hover:bg-green-400 hover:border-green-400 border-darkblue pr-3 pt-1 pb-1 rounded-full"
        >
          User
        </button>
      </div>
    </div>
    <div>
      <Table
        @clicked-table="NavigateToSingleTableItem($event)"
        @show-alert="ShowAlert($event)"
        :key="searchData.model"
        :FormatTableData="FormatTableData"
        :search_data="searchData"
        :table_data="table_data"
        :mode="mode"
        :TableButton="TableButton"
        :table_header="table_header"
      />
    </div>
  </div>
</template>

<script>
  import { mapState } from "vuex";
  import gql from "graphql-tag";
  import Cards from "../components/Cards.vue";
  const Table = () => import("../components/Table.vue");
  import BarChart from "../components/BarChart.vue";
  import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
  import { emitEventMixin } from "@/assets/helpers/emitEventMixin";

  export default {
    name: "Overview",
    props: {},
    mixins: [emitEventMixin],

    components: {
      Cards,
      FontAwesomeIcon,

      BarChart,
      Table,
    },
    apollo: {
      getItemsCount: gql`
        query {
          getItemsCount {
            totalInvoice
            totalCustomers
            totalPartners
          }
        }
      `,
    },
    data() {
      return {
        barChartYear: "2020",
        searchData: {
          model: "invoice",
          limit: 10,
          filter: null,
          searchQuery: null,

          cursor: 0,
        },
        searchFilterOptions: [
          { value: "customerName", text: "Customer Name" },
          { value: "status", text: "Status" },
        ],
        searchQuery: null,
        searchFilter: null,
        barChartYears: [],
        loading: {
          barChart: false,
        },
        getItemsCount: {
          totalCustomers: 0,
          totalInvoice: 0,
          totalCount: 0,
        },
        mode: "invoice",

        table_header: [
          {
            id: "invoice-no",
            value: "invoice_no",
            text: "No.",
            className: "",
          },
          {
            value: "amount",
            text: "Amount(₦)",
            className: "fortunate-table",
          },
          {
            value: "customer_name",
            text: "Customer",
            className: "fortunate-table",
          },
          {
            value: "status",
            text: "Status",
          },
          {
            value: "regDate",
            text: "Reg. Date",
            className: "fortunate-table",
          },
        ],
        table_data: [],

        // paginated_table_data: [],
        icons: {
          pen: require("../assets/imgs/icons/white-pen.svg"),
        },
      };
    },
    computed: {
      tableUrl: function() {
        let url;
        switch (this.mode) {
          case "invoice":
            url = "manage_invoice";
            break;
          case "stock":
            url = "manageStock";
            break;
          case "user":
            url = "manageUser";
            break;

          default:
            break;
        }
        return url;
      },
      singleItemTableUrl: function() {
        let url;
        switch (this.mode) {
          case "invoice":
            url = "single_invoice";
            break;
          case "stock":
            url = "singleStock";
            break;
          case "user":
            url = "singleUser";
            break;

          default:
            break;
        }
        return url;
      },
      ...mapState({
        role: "role",
      }),
      card_data: function() {
        return [
          { label: "Total Invoices", value: this.getItemsCount.totalInvoice },
          {
            label: "Total Customers",
            value: this.getItemsCount.totalCustomers,
          },
          { label: "Total Partners", value: this.getItemsCount.totalPartners },
        ];
      },
      invoice_mode: function() {
        return this.mode === "invoice";
      },
      stock_mode: function() {
        return this.mode === "stock";
      },
      user_mode: function() {
        return this.mode === "user";
      },
    },
    watch: {
      mode(newValue) {
        this.searchData.filter = null;
        this.searchFilter = null;
        this.searchData.searchQuery = null;
        this.table_data = [];
        switch (newValue) {
          case "invoice":
            this.table_header = [
              {
                id: "invoice-no",
                value: "invoice_no",
                text: "No.",
                className: "",
              },
              {
                value: "amount",
                text: "Amount(₦)",
                className: "fortunate-table",
              },
              {
                value: "customer_name",
                text: "Customer",
                className: "fortunate-table",
              },
              {
                value: "status",
                text: "Status",
              },
              {
                value: "regDate",
                text: "Reg. Date",
                className: "fortunate-table",
              },
            ];

            this.searchFilterOptions = [
              { value: "customerName", text: "Customer Name" },
              { value: "status", text: "Status" },
            ];

            break;

          case "stock":
            this.table_header = [
              {
                value: "itemName",
                text: "Stock Name",
                className: "fortunate-table",
              },
              {
                value: "quantityRemaining",
                text: "Quantity Remaining",
                className: "fortunate-table",
              },
              {
                value: "regDate",
                text: "Reg. Date",
                className: "fortunate-table",
              },
            ];

            this.searchFilterOptions = [
              { value: "itemName", text: "Stock Name" },
            ];

            break;
          case "user":
            this.table_header = [
              {
                value: "name",
                text: "Name",
                className: "fortunate-table",
              },
              {
                value: "position",
                text: "Position",
                className: "fortunate-table",
              },
              {
                value: "role",
                text: "Role",
                className: "fortunate-table",
              },
              {
                value: "phoneNumber",
                text: "Phone No.",
                className: "fortunate-table",
              },
              {
                value: "regDate",
                text: "Reg. Date",
                className: "fortunate-table",
              },
            ];

            this.searchFilterOptions = [
              { value: "name", text: "Name" },
              { value: "role", text: "Role" },
            ];

            break;
        }
        this.searchData.model = newValue;
      },
    },
    methods: {
      SearchTable() {
        this.searchData.filter = this.searchFilter;
        this.searchData.searchQuery = this.searchQuery;
      },
      FormatTableData(data) {
        let formattedData;

        switch (this.mode) {
          case "invoice":
            formattedData = data.map((invoice) => {
              const date = new Date(Number(invoice.createdAt));

              return {
                id: invoice._id,
                invoice_no: invoice.serialId,
                amount: invoice.invoiceTotal,
                customer_name: invoice.customerId.customerName,
                status: invoice.completedPayment === true ? "paid" : "pending",
                regDate: `${date.getDate()}/${date.getMonth() +
                  1}/${date.getFullYear()}`,
                btn: "btn",
              };
            });

            break;
          case "stock":
            formattedData = data.map((stock) => {
              let date = new Date(Number(stock.createdAt));

              return {
                id: stock._id,
                itemName: stock.itemName,
                quantityRemaining: stock.quantityRemaining,
                regDate: `${date.getDate()}/${date.getMonth() +
                  1}/${date.getFullYear()}`,
                btn: "btn",
              };
            });

            break;
          case "user":
            formattedData = data.map((user) => {
              let date = new Date(Number(user.createdAt));

              return {
                id: user._id,
                name: user.name,
                position: user.position,
                role: user.roleId.name,
                phoneNumber: user.phoneNumber,
                regDate: `${date.getDate()}/${date.getMonth() +
                  1}/${date.getFullYear()}`,
                btn: "btn",
              };
            });

            break;

          default:
            break;
        }
        this.table_data = formattedData;
      },
      NavigateToSingleTableItem(id) {
        this.$router.push({
          name: this.singleItemTableUrl,
          params: { id: id },
        });
      },
      SetBarChartLoadingStatus(status) {
        this.loading.barChart = status;
      },
      SetBarChartYear(year) {
        this.barChartYear = year;
      },
      BarChartYears(start, end, length = end - start + 1) {
        const barChartYears = Array.from({ length }, (_, i) => start + i);

        barChartYears.reverse();

        this.barChartYears = barChartYears;
      },
      TableButton() {
        this.$router.push({ name: this.tableUrl, params: { mode: "new" } });
      },
      ToggleChartSpinner() {
        this.chart_loading = !this.chart_loading;
      },

      ChangeTableMode(mode) {
        this.mode = mode;
      },
    },
    mounted() {
      const thisYear = new Date().getFullYear();
      this.barChartYear = thisYear.toString();
      this.BarChartYears(thisYear - 10, thisYear);
    },
  };
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .custom-select.open .custom-select-options {
    display: block;
  }
  .custom-select li.selected {
    background-color: rgb(52, 211, 153);
  }
</style>
