import { extend } from "vee-validate";
import {
  required,
  numeric,
  alpha_spaces,
  alpha,
  alpha_num,
  double,
  email,
} from "vee-validate/dist/rules";

extend("address", {
  message: "{_field_} only accepts numbers,alphabets and . , -",

  validate: (value) => {
    //eslint-disable-next-line
    const pattern = new RegExp(/[^\w\d\s\/|.,-]/);
    return !pattern.test(value);
  },
});

extend("custom_text", {
  message: "{_field_} only accepts numbers,alphabets,slashes and . , - ) (",

  validate: (value) => {
    //eslint-disable-next-line
    const pattern = new RegExp(/[^\w\d\s\\/|.)(,-]/);
    return !pattern.test(value);
  },
});

extend("numeric", {
  ...numeric,
  message: "{_field_} must be numbers only",
});

extend("email", {
  ...email,
  message: "{_field_} must be a valid email",
});
extend("alpha", {
  ...alpha,
  message: "{_field_} must be alphabets only",
});

extend("required", required);
extend("double", {
  ...double,
  message: "{_field_} must be a decimal value e.g 2.0",
});
extend("alpha_spaces", {
  ...alpha_spaces,
  message: "{_field_} must be alphabets only",
});
extend("alpha_num", {
  ...alpha_num,
  message: "{_field_} must be alphabets and numbers only",
});

extend("min_value", {
  validate(value, { min }) {
    return value >= min;
  },
  params: ["min"],
  message: "{_field_} must be greater or equal to {min}",
});
