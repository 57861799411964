import Vue from "vue";
import App from "./App.vue";
import "@/assets/css/tailwind.css";

import router from "./router";
import store from "./store";
import Axios from "axios";
import VueAxios from "vue-axios";
import VueTailwind from "vue-tailwind/dist/full";
import { ApolloClient } from "apollo-client";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faUserSecret,
  faPen,
  faCheckCircle,
  faShoppingBasket,
  faTimes,
  faTimesCircle,
  faArchive,
  faCar,
  faMap,
  faCreditCard,
  faPlus,
  faSpinner,
} from "@fortawesome/free-solid-svg-icons";
import { faTrashAlt } from "@fortawesome/free-regular-svg-icons";
// import { onError } from "apollo-link-error";
import { ApolloLink } from "apollo-link";
import { HttpLink } from "apollo-link-http";
import "./assets/helpers/validation";
import introspectionQueryResultData from "./fragmentTypes.json";
import {
  InMemoryCache,
  IntrospectionFragmentMatcher,
} from "apollo-cache-inmemory";
import VueApollo from "vue-apollo";
import "./registerServiceWorker";
const settings = {};

library.add(
  faUserSecret,
  faPen,
  faCar,
  faPlus,
  faMap,
  faTrashAlt,
  faCreditCard,
  faArchive,
  faTimesCircle,
  faShoppingBasket,
  faTimes,
  faSpinner,
  faCheckCircle
);
// get apollo fragment types from json file
const fragmentMatcher = new IntrospectionFragmentMatcher({
  introspectionQueryResultData: introspectionQueryResultData,
});

const AuthLink = new ApolloLink((operation, forward) => {
  // Called before operation is sent to server
  operation.setContext({
    headers: {
      authorization: store.state.token ? `${store.state.token}` : "",
    },
  });

  return forward(operation).map((data) => {
    // Called after server responds
    if (data.errors) {
      const errorResponse = data.errors.find((error) => {
        return error.extensions.code == "UNAUTHENTICATED";
      });

      if (errorResponse && router.currentRoute.path != "/") {
        store.state.token = null;
        router.push("/?sessionExpired=true");
        return;
      }
    }

    return data;
  });
});

const httpLink = ApolloLink.from([
  // ErrorLink,
  AuthLink,
  new HttpLink({ uri: process.env.VUE_APP_API_URL }),
]);

// Cache implementation
const cache = new InMemoryCache({ fragmentMatcher });

console.log(process.env.VUE_APP_NODE_ENV);
// Create the apollo client
const apolloClient = new ApolloClient({
  connectToDevTools: process.env.VUE_APP_NODE_ENV === "development",
  link: httpLink,
  cache,
  defaultOptions: {
    watchQuery: {
      errorPolicy: "all",
    },
    query: {
      errorPolicy: "all",
    },
    mutate: {
      errorPolicy: "all",
    },
  },
});

Vue.config.productionTip = false;
Vue.use(VueTailwind, settings);

// Vue.component("font-awesome-icon", FontAwesomeIcon);

Vue.use(VueApollo);

const apolloProvider = new VueApollo({
  defaultClient: apolloClient,
});

Vue.use(VueAxios, Axios);
new Vue({
  router,
  apolloProvider,
  store,
  render: (h) => h(App),
}).$mount("#app");
// })();
